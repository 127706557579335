<template>
  <div>
    <v-row>
      <!-- Title -->
      <v-col cols="12" md="8">
        <h4>Salvestatud markerid</h4>
        Kõik kasutatud RFID-märgised registreerimise järjekorras.
      </v-col>
      <!-- Finder view button -->
      <v-col>
        <v-btn to="/" block color="warning" class="mt-2">Tagasi lugema</v-btn>
      </v-col>
    </v-row>
    <!-- Search -->
    <v-row>
      <v-col>
        <h4>Otsing</h4>
        <v-text-field label="Seerianumber" v-model="searchKey" clearable></v-text-field>
        <v-btn class="mr-2 mb-2" color="warning" @click="searchDb()">Otsi</v-btn>
      </v-col>
    </v-row>
    <!-- Filters -->
    <v-row>
      <v-col>
        <h4>Filtreeri</h4>
        <v-btn class="mr-2 mb-2" :color="selectedTypeButton(type)" v-for="type in $store.state.filter.types" :key="type" @click="setFilterType(type)">
          <span v-if="type === 'last'">Viimased</span>
          <span v-else>{{ type }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <!-- Marker table -->
    <v-row>
      <v-col>
        <!-- No results -->
        <v-alert type="info" v-if="tags.length === 0 && !loading">
          <h3>Tulemusi ei leitud</h3>
          {{ $store.state.filter.type }} tüübiga RFID-märgiseid ei leitud andmebaasist
        </v-alert>

        <!-- Results table -->
        <v-simple-table v-else>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left"><small>Kiibi nr</small></th>
              <th><small>Registreeritud</small></th>
              <th class="text-left">Ballooni seerianumber</th>
              <th class="text-left">Tüüp</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="tag in dbTags" :key="tag.id">
              <td><small>{{ tag.id }}</small></td>
              <td><small>{{ $moment(tag.registered).format('DD.MM.YYYY HH:mm') }}</small></td>
              <td>{{ tag.sn }}</td>
              <td>{{ tag.type }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db } from '@/firebase'

export default {
  data () {
    return {
      tags: [],
      dbTags: [],
      searchKey: '',
      loading: true
    }
  },
  mounted () {
    this.readDb()
  },
  watch: {
    dbTags: function () {
      this.tags = this.dbTags
    },
    searchKey: function () {
      if (this.searchKey === null) {
        this.readDb()
      }
    }
  },
  methods: {
    selectedTypeButton (type) {
      return (this.$store.state.filter.type === type) ? 'warning' : 'default'
    },
    // Read from DB based on selected filter
    readDb () {
      const filteredEntries = (this.$store.state.filter.type === 'last') ? db.collection('tags').orderBy('registered', 'desc').limit(25) : db.collection('tags').where('type', '==', this.$store.state.filter.type).orderBy('registered', 'desc')
      this.$bind('dbTags', filteredEntries)
      this.loading = false
    },
    // Search database
    searchDb () {
      this.searchKey = this.searchKey.toUpperCase()
      const filteredEntries = (this.$store.state.filter.type === 'last') ? db.collection('tags').orderBy('registered', 'desc').where('sn', '==', this.searchKey) : db.collection('tags').where('type', '==', this.$store.state.filter.type).where('sn', '==', this.searchKey).orderBy('registered', 'desc')
      this.$bind('dbTags', filteredEntries)
    },
    // Set filter
    setFilterType (type) {
      this.loading = true
      this.$store.commit('setFilterType', type)
      this.readDb()
    }
  }
}
</script>
